import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, ListGroup, Overlay, Popover } from "react-bootstrap";

/**
 * Easy to define call to action button, which supports a
 * default text and link value.
 */
const SearchableDropdown = ({
  options = [],
  onOptionChosen,
  minimumOptions = [],
  placeholder,
}) => {
  const [searchKey, setSearchKey] = useState("");
  const target = useRef(null);
  const itemHeight = 50;
  const groupHeight = (itemHeight * minimumOptions.length) || 300

  let visibleOptions = !searchKey
    ? options
    : options.filter((option) =>
        option
          .toLowerCase()
          .replace(/[^a-zA-Z\-]/g, "")
          .includes(searchKey.toLowerCase().replace(/[^a-zA-Z\-]/g, ""))
      );

  minimumOptions.forEach((option) => {
    if (visibleOptions.includes(option)) return;
    visibleOptions.push(option);
  });

  const id = "searchable-dropdown";
  const href = `#${id}`;
  return (
    <div id={id} className="searchable-dropdown">
      <Form ref={target}>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={placeholder}
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            onFocus={() => {
              document
                .querySelector(href)
                ?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        </Form.Group>
      </Form>
      <ListGroup variant="flush" style={{height: `${groupHeight}px`}}>
        {visibleOptions.map((option) => (
          <ListGroup.Item onClick={() => onOptionChosen(option)}>
            {option}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

SearchableDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  minimumOptions: PropTypes.arrayOf(PropTypes.string),
  onOptionChosen: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default SearchableDropdown;
