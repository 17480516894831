import React from "react";
import { graphql, navigate } from "gatsby";
import Container from "react-bootstrap/Container";
import HeroCard from "../../components/HeroCard";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import SearchableDropdown from "../../components/SearchableDropdown";
import { convertTitleToLinkablePath } from "../../utils/utils";
import appendQueryString from "../../utils/query";

const Solutions = (props) => {
  const solutions = props.data.allMarkdownRemark.edges.map(
    (edge) => edge.node.frontmatter
  );
  const options = solutions.map((solution) => solution.teaserTitle);
  const minimumOptions = solutions
    .filter((solution) => solution.isBasic)
    .map((solution) => solution.teaserTitle);
  const index = props.data.indexData;

  function goToSolution(option) {
    const solution = solutions.filter(
      (solution) => solution.teaserTitle === option
    )[0];
    if (!solution) return;
    navigate(appendQueryString(solution.path));
  }

  return (
    <Layout bodyClass="page-solutions">
      <SEO
        title={index.frontmatter.title}
        description={index.frontmatter.SEODescription}
        imageUrl={
          props.data.seoImage.frontmatter.headerImage.childImageSharp.fixed.src
        }
        path={index.frontmatter.path}
      />
      <HeroCard
        image={index.frontmatter.headerImage}
        title={index.frontmatter.headerTitle}
        subtitle={index.frontmatter.headerSubtitle}
        size="medium"
      />
      <Container className="content-container">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: index.html }}
        />
        <div className="search-box-container">
          <SearchableDropdown
            options={options}
            onOptionChosen={goToSolution}
            minimumOptions={minimumOptions}
            placeholder="How can we help you?"
          />
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query SolutionsQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: { isVisible: { eq: true } }
        fileAbsolutePath: { regex: "/solutions/" }
      }
      sort: { fields: [frontmatter___teaserTitle], order: ASC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            teaserTitle
            path
            isBasic
          }
        }
      }
    }
    indexData: markdownRemark(
      fileAbsolutePath: { regex: "/solution-index.md/" }
    ) {
      frontmatter {
        title
        path
        SEODescription
        headerImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerTitle
        headerSubtitle
      }
      html
    }
    seoImage: markdownRemark(
      fileAbsolutePath: { regex: "/solution-index.md/" }
    ) {
      frontmatter {
        headerImage {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default Solutions;
